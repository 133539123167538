<template>
  <ClientOnly>
    <div
      class="flex justify-between p-2 sm:p-5 bg-white absolute top-0 right-0 left-0"
    >
      <img
        src="@/assets/images/hamburger.svg"
        alt="Hamburger Menu"
        class="lg:hidden xs:block md:w-8 xs:w-5 mx-1 xs:pr-n4 my-2"
        @click="drawer"
      >
      <div
        class="info-text-nav lg:flex"
        :class="{ 'xs:hidden': !isDashboard }"
      >
        <p class="info-text">
          Need help? Speak to a licensed insurance agent now.
        </p>
        <div class="info-number">
          <img
            v-if="tenant.tenantKey === 'unitedmedicareadvisors'"
            src="@/assets/images/phone-outline-red.svg"
            alt=""
            class="md:w-8 xs:w-4 xs:mr-2"
          >
          <img
            v-else
            src="@/assets/images/phone-outline-orange.svg"
            alt=""
            class="md:w-8 xs:w-4 xs:mr-2"
          >
          <a
            :href="'tel:' + inboundPhoneRaw"
            class="flex font-bold lg:text-2xl xs:text-[11px]"
          >
            {{ computedInboundPhone }} | TTY: 711
          </a>
        </div>
      </div>
      <div
        v-show="user"
        class="size-max items-center block lg:flex justify-center"
      >
        <div>
          <AtomButton
            label="Shop for Plans"
            :style="`background: ${tenant.secondaryColor}; font-weight: 700;height: 32px;`"
            btn-class="w-full text-white flex justify-evenly items-center px-4 text-[10px] md:text-[14px] leading-[12px] md:leading-[24px]"
            @click="handleClickShopForPlans"
          >
            <template #icon-left>
              <svg
                width="18"
                height="18"
                class="md:mr-2 mr-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 3H5L5.4 5M5.4 5H21L17 13H7M5.4 5L7 13M7 13L4.707 15.293C4.077 15.923 4.523 17 5.414 17H17M17 17C16.4696 17 15.9609 17.2107 15.5858 17.5858C15.2107 17.9609 15 18.4696 15 19C15 19.5304 15.2107 20.0391 15.5858 20.4142C15.9609 20.7893 16.4696 21 17 21C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19C19 18.4696 18.7893 17.9609 18.4142 17.5858C18.0391 17.2107 17.5304 17 17 17ZM9 19C9 19.5304 8.78929 20.0391 8.41421 20.4142C8.03914 20.7893 7.53043 21 7 21C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19C5 18.4696 5.21071 17.9609 5.58579 17.5858C5.96086 17.2107 6.46957 17 7 17C7.53043 17 8.03914 17.2107 8.41421 17.5858C8.78929 17.9609 9 18.4696 9 19Z"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </AtomButton>
        </div>
        <div
          class="hidden relative lg:block border-bg-gray-200"
          @mouseover="accountDropdownOpen = true"
          @mouseleave="accountDropdownOpen = false"
        >
          <p class="info-text capitalize font-medium pl-6 py-1.5">
            Hi, {{ user.info?.givenName }} {{ user.info?.familyName }}
            <img
              src="@/assets/images/arrow-drop-down.svg"
              alt="bell"
              class="hidden md:inline pl-3"
            >
          </p>
          <ul
            id="accountDropdown"
            class="hidden absolute bg-white rounded shadow right-0"
            :class="{ open: accountDropdownOpen }"
          >
            <li>
              <nuxt-link to="/user">
                Profile
              </nuxt-link>
            </li>
            <!-- <li>
              <nuxt-link to="/user/account-information">
                Account
              </nuxt-link>
            </li> -->
            <li>
              <a
                href="#"
                @click.prevent="signOut"
              > Logout </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        id="drawer"
        class="size-max bg-smartmatch-purple absolute inset-0 p-5 lg:hidden z-50"
        :class="{ open: drawerOpen }"
        @click="drawer"
      >
        <div class="w-full flex">
          <div class="w-2/12 flex items-center">
            <img
              src="@/assets/images/hamburger-white.svg"
              alt="Hamburger Menu"
              class="lg:hidden w-8 ml-1 md:w-8 xs:w-6"
            >
          </div>
        </div>
        <div class="size-max bg-smartmatch-purple flex items-center mt-5">
          <div class="size-full pt-5">
            <p
              v-show="user"
              class="text-white font-roboto-bold xs:text-lg sm:text-xl md:text-2xl lg:text-4xl"
            >
              Hi, {{ user.info?.givenName }} {{ user.info?.familyName }}
            </p>
            <ul class="menu py-1">
              <li
                v-for="menu in burgerMenu"
                :key="menu.label"
                class="menu-item  sm:pt-1 md:py-3"
              >
                <span v-if="menu.children" class="font-roboto-reg xs:text-lg sm:text-xl md:text-2xl lg:text-4xl">{{ menu.label }}</span>
                <ul
                  v-if="menu.children"
                  class="submenu"
                >
                  <li
                    v-for="submenu in menu.children"
                    :key="submenu.label"
                    class="submenu-item  sm:pt-1 md:py-3"
                  >
                    <nuxt-link :to="submenu.link" class="font-roboto-reg xs:text-lg sm:text-xl md:text-2xl lg:text-4xl">
                      {{ submenu.label }}
                    </nuxt-link>
                  </li>
                </ul>
                <nuxt-link
                  class="font-roboto-reg xs:text-lg sm:text-xl md:text-2xl lg:text-4xl"
                  v-else
                  :to="menu.link"
                  :disabled="menu.children"
                >
                  {{ menu.label }}
                </nuxt-link>
              </li>
              <li class="menu-item xs:py-1 sm:pt-1 md:py-3">
                <a
                  class="font-roboto-reg xs:text-lg sm:text-xl md:text-2xl lg:text-4xl"
                  href="#"
                  @click.prevent="signOut"
                > Logout </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUserStore } from "~/store/user";
import { ref } from "vue";

const config = useRuntimeConfig();
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const { inboundPhoneRaw, user, computedInboundPhone, tenant } =
	storeToRefs(userStore);
const accountDropdownOpen = ref(false);
const drawerOpen = ref(false);

const burgerMenu = ref([
	{
		label: "Dashboard",
		link: "/dashboard",
	},
	{
		label: "Help",
		children: [
			{ label: "FAQ", link: "/faq" },
			{ label: "Resources", link: "/resources" },
		],
	},
	{
		label: "View Your Applications",
		link: "/applications",
	},
	{
		label: "View Your Policies",
		link: "/policies",
	},
	{
		label: "View Your Account",
		link: "/user",
		children: [
			{ label: "Profile", link: "/user" },
			{ label: "Manage Consent", link: "/user/manage-consent" },
			{ label: "Doctors", link: "/user/doctors" },
			{ label: "Medications", link: "/user/medications" },
			{ label: "Pharmacies", link: "/user/pharmacies" },
		],
	},
	{
		label: "Referral Program",
		link: "/referral",
	},
	// {
	// 	label: "See Your Health Profile",
	// 	link: "/user",
	// 	children: [
	// 		{ label: "Health", link: "/dashboard" },
	// 		{ label: "Medications", link: "/dashboard" },
	// 		{ label: "Doctors", link: "/dashboard" },
	// 		{ label: "Pharmacies", link: "/dashboard" },
	// 	],
	// },
]);

const isDashboard = computed(() => {
	return route.name === "dashboard";
});

const handleClickShopForPlans = async () => {
	await navigateTo(
		tenant.value.tenantKey === "smartmatchinsurancesolutions"
			? (config.public.SMIA_SUNFIRE_URL as string)
			: (config.public.UMA_SUNFIRE_URL as string),
		{
			open: {
				target: "_blank",
				external: true,
			},
		},
	);
};

function drawer() {
	drawerOpen.value = !drawerOpen.value;
}

async function signOut() {
	router.push("/sign-in");
	userStore.signOut();
}
</script>

<style scoped>
#drawer {
	transition: 0.5s ease;
	transform: translateX(-100%);
}

#drawer.open {
	transform: translateY(0px);
}

#drawer a {
	font-weight: bold;
	@apply text-white;
	/* @apply ml-5; */
	/* @apply p-2; */
}

.menu {
	@apply m-4;
	@apply space-y-4;
}

.menu-item span {
	color: white;
	font-weight: bold;
}

.submenu {
	@apply px-4;
}

#notifications.open,
#accountDropdown.open {
	display: block;
}

#accountDropdown a {
	display: block;
	@apply p-2.5;
	@apply w-36;
}

#accountDropdown a:hover,
#accountDropdown a.nuxt-link-exact-active,
#notifications li:hover {
	@apply bg-gray-200;
}

#drawer a:hover,
#drawer a.nuxt-link-exact-active {
	@apply text-smartmatch-orange;
}

.info-text-nav {
	flex-direction: column;
	text-align: left;
	align-items: flex-start;
}

.info-text {
	font-size: 16px;
	font-style: italic;
	font-weight: 500;
	line-height: 150%; /* 24px */
}

.info-number {
	display: flex;
	flex-direction: row;
	align-items: center;
	/* xs:pl-8 */
}

.navigation-mobile {
	width: fit-content;
	height: fit-content;
}

div#drawer {
	min-height: 100vh;
	min-width: 100vh;
}
@media (max-width: 600px) {
	.info-text {
		/* xs:text-[10px] md:font-medium xs:font-light */
		font-size: 12px;
		font-style: italic;
		font-weight: 500;
		line-height: 150%; /* 24px */
	}
	.info-number {
		display: flex;
		flex-direction: row;
		align-items: center;
		/* xs:pl-8 */
	}
}
</style>
