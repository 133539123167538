<template>
  <ClientOnly>
    <div
      class="bg-white md:px-20 xs:px-6 lg:pt-5 xs:pt-2 pb-3 text-center absolute bottom-0 left-0 right-0"
    >
      <div class="flex -mb-14">
        <img
          class="w-24 sm:w-28 md:w-32"
          v-show="tenant.logoUrl"
          :src="tenant.logoUrl"
          alt="Company Logo"
        >
        <img 
          v-show="tenant.tenantKey === 'smartmatchinsurancesolutions'" 
          class="mx-4 w-4 sm:w-5 md:w-6" 
          src="~/assets/images/plus_sign_small_purple.svg" 
          alt="plus_sign_purple"
        >
        <img
          v-show="tenant.tenantKey === 'smartmatchinsurancesolutions'"
          src="~/assets/images/SmartConnectTM_Purple.svg"
          class="w-24 sm:w-28 md:w-32"
          alt="smart_connect_purple"
        >
      </div>
      <div class="flex footer_text justify-between items-center">
        <div class="flex items-center justify-center flex-no-wrap xs:-mb-3">
          <b class="lg:text-xl xs:text-sm pr-2">&copy;</b>
          <p class="lg:text-sm xs:text-[10px] whitespace-nowrap">
            2022-{{ new Date().getFullYear() }}
            {{
              tenant.tenantKey === "unitedmedicareadvisors"
                ? "United Medicare Advisors"
                : "SmartMatch Insurance Agency"
            }}
          </p>
        </div>
        <div class="grid_text">
          <a
            :href="
              tenant.tenantKey === 'unitedmedicareadvisors'
                ? 'https://www.unitedmedicareadvisors.com/privacy-policy'
                : 'https://www.smartmatch.com/privacy-policy'
            "
            class="lg:text-sm xs:text-[10px]"
            target="_blank"
          >Privacy Policy</a>
          <a
            :href="
              tenant.tenantKey === 'unitedmedicareadvisors'
                ? 'https://www.unitedmedicareadvisors.com/terms-conditions'
                : 'https://www.smartmatch.com/terms-conditions'
            "
            class="lg:text-sm xs:text-[10px]"
            target="_blank"
          >Terms & Conditions</a>
          <span class="lg:text-sm xs:text-[10px]"><nuxt-link to="/auth-error">v1.0</nuxt-link></span>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
import { useUserStore } from "~/store/user";
import { storeToRefs } from "pinia";
const userStore = useUserStore();
const { tenant } = storeToRefs(userStore);
</script>

<style scoped>
img#company_logo{
  /* md:hidden md:block mx-auto md:mx-0 md:w-28 xs:w-2 md:px-2 pb-5 */
  position: absolute;
  left: 2%;
  top: 17%;
  width: 7rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1.5rem;
}
.footer_text{
  padding-top: 3rem;
  height: 11vh;
}
@media (min-width: 601px) {
  .grid_text{
  /* grid grid-rows-4 grid-flow-col gap-0 pt-3 */
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, auto));
  grid-auto-flow: column;
  gap: 15px;
}
}

@media (max-width: 600px) {
  img#company_logo{
    width: 4.5rem;
    position: absolute;
    left: 6%;
    top: 20%;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
  }
  .footer_div{
    margin-top: 10px;
  }
  .footer_text{
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
  }
  .grid_text{
    /* grid grid-rows-4 grid-flow-col gap-0 pt-3 */
    display: grid;
    grid-template-rows: repeat(3, minmax(auto, auto)) !important;
    grid-auto-flow: row;
    gap: 0px;
    padding-top: 0.75rem
  }
}
</style>