<template>
  <div class="flex items-center">
    <input
      :id="label"
      v-model="inputValue"
      type="checkbox"
      :name="label"
      :disabled="disable"
      class="rounded focus:outline-none focus:ring focus:ring-transparent text-smartmatch-orange"
      @input="emitInputData"
    >
    <label
      :for="label"
      class="pl-3"
    >{{ label }}</label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
	label: String,
	inputKey: String,
	disable: {
		type: Boolean,
		default: false,
	},
	value: Boolean,
});
const emit = defineEmits(["input"]);
const inputValue = ref(props.value);
watch(props, () => {
	inputValue.value = props.value;
});
function emitInputData() {
	emit("input", props.inputKey, !inputValue.value);
}
</script>
