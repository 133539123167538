<template>
  <div>
    <transition name="slide-fade">
      <div
        class="fixed inset-0 w-screen h-4/6 flex items-center justify-center z-10"
      >
        <div
          class="bg-white w-10/12 lg:w-6/12 p-8 shadowl-xl rounded m-auto modal-class"
        >
          <img
            :src="close"
            class="absolute right-10 z-10 cancel cursor-pointer"
            @click="$emit('onClose')"
          >
          <br>
          <iframe
            class="w-full iFrame"
            :src="iframeSrc"
            frameborder="0"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import close from "@/assets/images/x-smartmatch.svg";

const iframeSrc = ref(
	"https://springventuregroup.iad1.qualtrics.com/jfe/form/SV_0N8EsS1X01rBoQC",
);
</script>

<style scoped>
.modal-class {
	height: 90vh !important;
	margin-top: 30vh;
	position: absolute;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 0.3s ease;
}
.slide-fade-enter {
	transform: translateY(25px);
	opacity: 0.25;
}
.slide-fade-leave-to {
	transform: translateY(25px);
	opacity: 0;
}
.iFrame {
	height: 96.5%;
}
</style>
