import { InactivityTimekeeper } from "./inactivityTimeout";

export class InactivityTimekeeperFactory {
	private static instances: { [key: string]: InactivityTimekeeper } = {};

	public static getInstance(key: string): InactivityTimekeeper {
		if (!this.instances[key]) {
			this.instances[key] = new InactivityTimekeeper();
		}
		return this.instances[key];
	}

	public static removeInstance(key: string): void {
		if (this.instances[key]) {
			this.instances[key].stop();
			delete this.instances[key];
		}
	}
}
