<template>
  <div v-if="userStore.showProgressBar"> 
    <v-progress-linear
        :color="tenant.secondaryColor"
        indeterminate
    ></v-progress-linear>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '~/store/user';

const userStore = useUserStore();
const { tenant } = storeToRefs(userStore);
</script>