<template>
  <div class="">
    <div
      class="absolute inset-0 bg-black opacity-50 flex items-center justify-center"
    />
    <div
      class="bg-white modal-container rounded-md h-auto absolute md:px-0"
    >
      <div>
        <div class="absolute top-6 right-0 h-16 w-12">
          <input
            type="image"
            :src="isUMA ? closeRed : closeOrange"
            class="h-5 w-5"
            @click="emit('close')"
          >
        </div>
      </div>
      <div class="flex flex-col gap-4 xs:py-5 md:py-10 xs:mt-5">
        <div class="w-full flex justify-center">
          <img
            :src="isUMA ? callRed : callOrange"
            alt="orange check"
            class="w-12 text-center mb-6"
          >
        </div>

        <h3 class="lg:text-lg xs:text-md text-center roboto-bold">
          Missing Information
        </h3>
        <p class="text-center text-sm xs:px-6 md:px-16 md:text-base">
          Looks like we are missing some key location
          information in order to connect you with your
          exact plan documents. Please call the number
          below to speak with a representative who will
          be happy to help get your information updated!
        </p>
        <p class="text-center">
          <a
            :href="'tel:' + inboundPhone"
            class="text-xl sm:text-2xl md:text-3xl lg:text-4xl px-2 roboto-bold"
            :class="isUMA ? 'text-uma-red' : 'text-smartmatch-orange'">
            {{ computedInboundPhone }} | TTY: 711
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import closeOrange from "@/assets/images/x-orange.svg";
import closeRed from "@/assets/images/x-red.svg";
import callOrange from "@/assets/images/call-orange.svg";
import callRed from "@/assets/images/call-red.svg";
import { useUserStore } from "~/store/user";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { inboundPhone, computedInboundPhone, tenant } = storeToRefs(userStore);
const emit = defineEmits(["close"]);

const isUMA = computed(() => tenant.value.tenantKey === 'unitedmedicareadvisors');
</script>

<style>
.modal-container {
  height: fit-content;
  top: 10%;
	left: 0;
  right: 0;
  margin: 0 auto;
  width: 50%;
  max-width: 500px;
}

@media (max-width: 600px) {
  .modal-container {
    top: 15vw;
    width: 80%;
  }
}
@media ( max-height: 700px ) and ( max-width:400px ) {
  .modal-container {
    top: 4vw;
    width: 90%;
  }
}
</style>
