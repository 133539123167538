<template>
  <div class="">
    <div
      class="absolute inset-0 bg-black opacity-50 flex items-center justify-center"
    />
    <div
      class="bg-white no-account-class rounded-md h-auto consentModal"
    >
      <div>
        <div class="absolute top-6 right-0 h-16 w-12">
          <input
            type="image"
            :src="close"
            class="h-5 w-5"
            @click="emit('close')"
          >
        </div>
      </div>
      <div class="flex flex-col gap-4 md:px-16 xs:p-6 pb-6 pt-11 xs:mt-7">
        <div class="w-full flex justify-center">
          <img
            src="@/assets/images/check-circle-orange.svg"
            alt="orange check"
            class="w-12 text-center"
          >
        </div>

        <h3 class="lg:text-lg xs:text-md font-extrabold text-center">
          Transfer Disclosure Consent
        </h3>
        <p class="text-center disclosureText">
          You may give permission to share your protected health information
          with our partners.
        </p>
        <p class="disclosureText">Disclosure</p>
        <div class="w-full disclosureDiv overflow-auto bg-gray-50 p-5 rounded-lg disclosureText">
          <p>
            I authorize [my health plan and] Spring Venture Group (including its
            affiliates) to use my contact information and general demographic
            information, to provide me with information related to other
            health-related and non-health related services that I may be
            interested in that are outside of my health plan’s plan of benefits,
            including instances where Spring Venture Group [or my health plan]
            receives a payment for providing me with such information. I
            understand I can revoke this authorization at any time. If I choose
            to revoke this authorization, I understand the revocation will not
            impact uses and disclosures of my information already made in
            reliance on this authorization. I understand that my treatment or
            eligibility for health plan benefits will not be conditioned on
            whether I agree to this authorization. I understand that any
            information disclosed pursuant to this authorization may be subject
            to redisclosure by the recipient and no longer protected by HIPAA. I
            have the right to request an executed copy of this authorization.
          </p>
        </div>
        <div class="flex justify-evenly">
          <AtomCheckBox
            label="I Consent"
            input-key="consent"
            :value="consent"
            @input="handleInput"
          />
          <AtomCheckBox
            label="I Decline"
            input-key="decline"
            :value="decline"
            @input="handleInput"
          />
        </div>
        <AtomButton
          label="Submit"
          :style="`background: ${tenant.secondaryColor};`"
          :disabled="!consent && !decline"
          @click="answerConsentQuestion"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import close from "@/assets/images/x-orange.svg";
import { useUserStore } from "~/store";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { tenant } = storeToRefs(userStore);
const emit = defineEmits(["close"]);
const consent: Ref<boolean> = ref(false);
const decline: Ref<boolean> = ref(false);
function handleInput(key: string, val: boolean) {
	switch (key) {
	case "consent":
		consent.value = val;
		decline.value = false;
		break;
	case "decline":
		consent.value = false;
		decline.value = val;
		break;
	}
}

function answerConsentQuestion() {
	userStore.submitUserConsent(consent.value);
	emit("close");
}
</script>

<style>
.no-account-class {
	max-width: 562px;
}
.consentModal {
  /* xs:inset-0 md:w-1/2 xs:w-full */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.disclosureDiv{
  height: 14rem;
}

@media (max-width: 481px) {
  .consentModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  .disclosureText{
    font-size: 14px;
  }
  .disclosureDiv{
    height: 40vw;
    scrollbar-color: #FD734D #FD734D;
  }
}

@media (min-width: 482px) and (max-width: 768px) {
  .consentModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  .disclosureText{
    font-size: 14px;
  }
  .disclosureDiv{
    height: 40vw;
    scrollbar-color: #FD734D #FD734D;
  }
}

@media (min-width: 789px) and (max-width: 1000px) {
  .consentModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  .disclosureText{
    font-size: 14px;
  }
  .disclosureDiv{
    height: 50vw;
    scrollbar-color: #FD734D #FD734D;
  }
}
@media ( max-height: 700px ) and ( max-width:400px ) {
  .consentModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
  }
  .disclosureText{
    font-size: 14px;
  }
  .disclosureDiv{
    height: 65vw;
    scrollbar-color: #FD734D #ffffff00;
  }
}
</style>
