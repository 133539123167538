<template>
  <div class="noauth">
    <div class="noauth-color">
      <div class="flex flex-col items-center justify-center h-full">
        <div
          class="box-content flex col items-center justify-center bg-white px-8 shadow flex-col relative"
        >
          <h1 class="font-heading mb-4 h-12 text-center">
            Are you still there?
          </h1>
          <div class="helper-text">
            <p>
              For security purposes your session will time out after 5 minutes
              of inactivity. Please click the button below to restart the timer
              for your session. If you are inactive for 15 minutes you will be
              redirected to the login page to access your Customer Benefits
              Portal.
            </p>
          </div>
          <button
            class="still-here-button hover:shadow-lg rounded text-white"
            :style="`background-color: ${tenant.secondaryColor}`"
            @click="stillHere()"
          >
            I'm still here!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCwmpStore, useUserStore } from "~/store";
import { storeToRefs } from "pinia";

const emit = defineEmits(["yes-still-here"]);
const cwmpStore = useCwmpStore();
const userStore = useUserStore();

const { tenant } = storeToRefs(userStore);

// emit a yes-still-here event to the parent component to extend the timeout
const stillHere = () => {
	console.log("Yes, Still here - emitting event");
	emit("yes-still-here");
};
const handleCloseClick = () => {
	console.log("Close icon clicked");
	emit("yes-still-here");
};
/**
 * Alpha adds transparency so we add a 50% transparency for the gradient css
 * @type {ComputedRef<string>}
 */
const primaryColorAlpha = computed(() => {
	return cwmpStore.getThemeColor("primary") + "80";
});
const secondaryColor = computed(() => {
	return cwmpStore.getThemeColor("secondary");
});
</script>
<style scoped>
.noauth {
	background-image: url("~/assets/images/noauth-bg.svg");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh !important;
	background-color: gray;
}

.noauth-color {
	/* position: absolute; */
	background-size: cover;
	min-height: 100vh !important;
	background: v-bind(primaryColorAlpha) no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
}
.form-bg {
	font-family: "Raleway", sans-serif;
}
.box-content {
	max-width: 300px !important;
	width: 300px !important;
	max-height: 310px !important;
	height: 310px !important;
	min-height: 310px !important;
	border-radius: 10px;
}
@media (min-width: 700px) {
	.box-content {
		max-width: 498px !important;
		width: 498px !important;
		max-height: 412px !important;
		height: 412px !important;
		min-height: 412px !important;
	}
}
.flex {
	align-items: center;
}
.col {
	flex: 1;
}
.col-img {
	position: relative;
}

.font-heading {
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
	max-height: 27px;
	text-align: center;
	color: black;
}
@media (min-width: 700px) {
	.font-heading {
		line-height: 27px;
	}
}

.helper-text {
	font-family: "Raleway", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal !important;
	text-align: center;
	width: 290px;
	height: 115px;
	max-height: 115px;
	margin-top: 0;
	margin-bottom: 0;
}
@media (min-width: 700px) {
	.helper-text {
		font-size: 16px;
		line-height: 24px;
		width: 355px;
		height: 144px;
		max-height: 144px;
		margin-top: 16px;
		margin-bottom: 24px;
	}
}
.helper-text p {
	line-height: normal !important;
}
@media (min-width: 700px) {
	.helper-text p {
		line-height: 24px !important;
	}
}
.close-x-icon :deep(path) {
	fill: v-bind(secondaryColor) !important;
}
.close-x-icon {
	height: 16px !important;
	width: 16px !important;
}
@media (min-width: 700px) {
	.close-x-icon {
		height: 21px !important;
		width: 21px !important;
	}
}
.timer-icon {
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	margin-bottom: 16px;
	margin-top: 13px;
}
@media (min-width: 700px) {
	.timer-icon {
		width: 64px;
		height: 64px;
	}
}
.timer-icon :deep(path) {
	fill: v-bind(secondaryColor) !important;
	fill-opacity: 1;
}
.still-here-button {
	color: #fff;
	background-color: v-bind(secondaryColor);
	text-align: center;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	width: 120px;
	height: 40px;
	padding: 6px 8px;
}
@media (min-width: 700px) {
	.still-here-button {
		font-size: 16px;
		font-weight: 700;
		line-height: 150%;
		width: 408px;
		/* height: 44px; */
	}
}
</style>
