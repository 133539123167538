<template>
  <div class="flex h-screen relative">
    <SideNav />
    <div class="w-full bg-gray-50 relative sm:py-20 xs:py-12">
      <Header />
      <AtomProgressBar />
      <div
        v-if="!isDashboard"
        class="lg:ml-8 my-4 xs:hidden lg:block"
      >
        <nuxt-link to="/dashboard">
          <img
            src="@/assets/images/arrowBack.svg"
            alt="arrowBack"
            class="inline pl-3 pr-3 w-1/7"
          >
          Back to Dashboard
        </nuxt-link>
      </div>
      <div class="feedback-container" />
      <div 
        class="feedback fixed top-0 h-80 transform -rotate-90"
        :class="{hidden:consentModal}"
      >
        <button
          class="feedback-button"
          :class="{uma:tenant.tenantKey === 'unitedmedicareadvisors', smartmatch:tenant.tenantKey === 'smartmatchinsurancesolutions'}"
          @click="showModal = true"
        >
          Feedback
        </button>
      </div>

      <div :style="isDashboard ? 'height: 97%' : 'height: 90%'">
        <slot />
        <StillThere
          v-if="showStillThere"  
          class="overlay-still-there"
          @yes-still-here="handleYesStillHere()"
        />
      </div>
      <Footer />
    </div>
    <FeedbackModal
      v-if="showModal"
      class="overlay"
      @on-close="showModal = false"
    />
    <ConsentModal
      v-if="consentModal"
      @close="consentModal = false"
    />
    <MissingInformationModal
      v-if="showMissingInfoModal"
      @close="showMissingInfoModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "~/store";
import { storeToRefs } from "pinia";
import { InactivityTimekeeper } from "~/helpers/inactivityTimeout";
import { InactivityTimekeeperFactory } from "~/helpers/inactivityTimekeeperFactory";
import StillThere from "~/components/molecules/inactivity/StillThere.vue";
// Inactivity Timeout variables
const showStillThere = ref(false);
let inactivityTimekeeper: InactivityTimekeeper | null = null;
const router = useRouter();


const userStore = useUserStore();
const { consentModal, showMissingInfoModal, tenant } = storeToRefs(userStore);
const route = useRoute();
// const router = useRouter();
const showModal = ref(false);
const isDashboard = computed(() => {
	return route.name === "dashboard";
});
// const time: Ref<NodeJS.Timeout | undefined> = ref(undefined);
// const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
// function resetTimer() {
// 	console.log("****resetTimer");
// 	clearTimeout(time.value);
// 	time.value = setTimeout(logout, 120000);
// }

// function logout() {
// 	router.push("/session-timeout");
// 	userStore.signOut();
// 	events.forEach(function (name) {
// 		document.removeEventListener(name, resetTimer, true);
// 	});
// }

// onMounted(() => {
// 	resetTimer();
// 	events.forEach(function (name) {
// 		document.addEventListener(name, resetTimer, true);
// 	});
// });

// onUnmounted(() => {
// 	clearTimeout(time.value);
// 	events.forEach(function (name) {
// 		document.removeEventListener(name, resetTimer, true);
// 	});
// });
// === INACTIVITY TIMEOUT HANDLING ===
const handleYesStillHere = () => {
	// Logic to hide StillThere component
	console.debug("Hide StillThere component");
	showStillThere.value = false;
	inactivityTimekeeper?.reset();
};

const handleShowStillThere = (event: CustomEvent) => {
	// Logic to show StillThere component
	if (event.detail.app == "CBP") {
		const show = event?.detail?.show;
		console.debug(`Show StillThere component: ${show} for CBP`);
		showStillThere.value = show;
	}
};

const handleAutoLogout = (event: CustomEvent) => {
	// Logic for auto-logout
	if (event.detail.app == "CBP") {
		console.debug("Handle auto-logout for CBP");
		showStillThere.value = false;
		// CBP should redirect to the session timeout page.
		console.debug(
			"CBP should redirect to session timeout page here which should allow user to log back in",
		);
		router.push("/session-timeout");
	}
};

const handleCwmpWindowObtained = (event: CustomEvent) => {
	const cwmpWindow = event.detail.window;
	const cwmpWindowOrigin = event.detail.origin;
	console.debug("CWMP Window obtained:", cwmpWindow);
	// Add the other app
	inactivityTimekeeper?.addRelatedApp("CWMP", cwmpWindowOrigin, cwmpWindow);
};

onBeforeMount(() => {
  userStore.resetInfoModal()
})

onMounted(() => {
	// Inactivity Handling section
	console.debug(
		"In onMounted of cwmpClientLayout - creating new InactivityTimekeeper",
	);
	inactivityTimekeeper =
    InactivityTimekeeperFactory.getInstance("cwmpClientLayout");
	inactivityTimekeeper.start(
		"customer-benefits-portal--cwmpClientLayout",
		"CBP",
	);
	document.addEventListener(
		"cwmp-window-obtained",
    handleCwmpWindowObtained as EventListener,
	);
	document.addEventListener(
		"inactivity-timekeeper-show-still-there",
    handleShowStillThere as EventListener,
	);
	document.addEventListener(
		"inactivity-timekeeper-auto-logout",
    handleAutoLogout as EventListener,
	);
});

onUnmounted(() => {
	// Inactivity Handling section
	console.debug(
		"In onUnmounted of cwmpClientLayout - removing InactivityTimekeeper",
	);
	inactivityTimekeeper?.stop();
	InactivityTimekeeperFactory.removeInstance("cwmpClientLayout");
	inactivityTimekeeper = null;
	document.removeEventListener(
		"cwmp-window-obtained",
    handleCwmpWindowObtained as EventListener,
	);
	document.removeEventListener(
		"inactivity-timekeeper-show-still-there",
    handleShowStillThere as EventListener,
	);
	document.removeEventListener(
		"inactivity-timekeeper-auto-logout",
    handleAutoLogout as EventListener,
	);
});

</script>
<style scoped>
.feedback {
	right: -159px;
}
.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	transition: opacity 200ms;
}
.overlay .cancel {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: default;
}
.overlay:target {
	visibility: visible;
}
.feedback-container {
	display: flex;
	flex-direction: column-reverse;
}
.feedback-button {
	color: #ffffff;
	font-style: normal;
	padding: 10px;
	border-radius: 4px 4px 0px 0px;
  font-size: 16px;
}
.smartmatch {
  background-color: #fd734d;
}
.uma {
  background-color: #a21d21;
}

@media (max-width: 600px){
  .feedback-button {
    color: #ffffff;
    font-style: normal;
    padding: 5px;
    border-radius: 4px 4px 0px 0px;
    font-size: 10px;
  }
  .feedback {
	  right: -159px;
    top: -75px
  }
  .smartmatch {
	  background-color: #fd734d;
  }
  .uma {
    background-color: #a21d21;
  }
}
.overlay-still-there {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Ensure it is on top of other elements */
}
.overlay-timed-out-cwmp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Ensure it is on top of other elements */
}
</style>
